<template>
  <div class="about">
    <h1 class="display3">About us</h1>
    <div class="card p-3">
      <h4>We are KeySolutions</h4>
      <p>text text text texttext texttext texttext text</p>
      <span>Business Email:</span> email@gmail.com
    </div>
  </div>
</template>
